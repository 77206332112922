<template>
  <button class="add-to-cart" @click="addToCart">
    <slot></slot>
    <i class="las la-cart-plus" v-if="!loading && !success"></i>
    <div class="loading" v-if="loading">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="success" v-if="success">
      <i class="las la-check"></i>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    quantity: {
      type: Number,
    },
  },
  data() {
    return {
      loading: false,
      success: false,
    };
  },
  methods: {
    async addToCart() {
      this.loading = true;
      const existingQuantity = this.$store.getters["cart/getProductQuantity"](
        this.product
      );
      const quantity = existingQuantity
        ? existingQuantity + this.quantity
        : this.quantity;
      try {
        await this.$store.dispatch("cart/addToCart", {
          ...this.product,
          quantity: quantity,
        });
        this.loading = false;
        this.success = true;
        setTimeout(() => {
          this.success = false;
        }, 1000);
      } catch (err) {
        console.log(err.response.data.message);
        this.loading = false;
        this.success = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-to-cart {
  border: none;
  background-color: var(--iq-primary);
  color: #fff;
  border-radius: 4px !important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;

  i {
    &.la-cart-plus {
      font-size: 1.7rem;
    }
    &.la-check {
      font-size: 1.2rem;
    }
  }
  .spinner-border {
    width: 1.2rem;
    height: 1.2rem;
  }
}
</style>